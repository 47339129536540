// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4521 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  overflow: hidden;

  @media only screen and (max-height: 800px) {
    min-height: 990px;
  }

  &:before {
    position: absolute;
    content: "";
    background: url(/i/design/slider-left.png);
    top: -140px;
    left: -200px;
    height: 586px;
    width: 604px;

    @media only screen and (max-width: 1300px) {
      left: -340px;
    }

    @media only screen and (max-width: 1170px) {
      opacity: 0.6;
    }

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: "";
    background: url(/i/design/slider-left.png);
    bottom: -90px;
    right: -250px;
    height: 586px;
    width: 604px;
    transform: rotate(180deg) scaleX(-1);
    z-index: -1;

    @media only screen and (max-width: 1300px) {
      right: -400px;
    }

    @media only screen and (max-width: 1170px) {
      opacity: 0.6;
    }

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  .backstretch {
    position: relative;

    // for top of page gradient
    &:after {
      @include before(100%, 650px);
      background: url(/i/design/gradient-btm.png) repeat-x bottom left;
      bottom: 0;
      left: 0;
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) no-repeat top center;
    top: 0;
    left: 0;
    right: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  top: 240px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 36px;
  transition: 0.3s;

  @media only screen and (max-width: 990px) {
    top: 210px;

  }

  @media only screen and (max-width: 700px) {
    font-size: clamp(1.56rem, calc(1rem + 3vw), 2.5rem);
  }
}

.building-graphic {
  position: relative;
  width: 100%;

  &::before {
    position: absolute;
    content: "";
    width: 1038px;
    height: 380px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url(/i/design/buildings-graphic.png);
  }

  @media only screen and (max-width: 990px) {
    display: none;
    
  }
}
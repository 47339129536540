// // ------------------------------ Bx Slider
// $('#bxslider').bxSlider({
// 	mode: 'fade',
// 	auto: true,
// 	pager: true,
// 	controls: false,
// 	// slideWidth: 500,
// 	autoControls: false,
// 	pause: 7000
// });

// BXSLIDER

.bx-cont {
  margin: 0 auto;
  max-width: 900px;
  text-align: center;
  color: #fff;
  padding: 0 15px;

  ul#bxslider {

    li {
      padding: 0;
      background: none;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
      color: #FFF;
      font-size: 36px;
      line-height: 48px;

      @media only screen and (max-width: 940px) {
        font-size: clamp(1rem, calc(0.58rem + 2.22vw), 2.25rem);
        line-height: normal;
			}

      span {
        text-transform: uppercase;
        font-size: 18px;

        @media only screen and (max-width: 940px) {
          font-size: clamp(1rem, calc(0.96rem + 0.22vw), 1.13rem);
        }
      }
    }
  }
}

// BXSLIDER CONTROLS - INDICATORS

.bx-controls {
  display: block;
  text-align: center;
  margin: 30px auto;
  border-radius: 50%;
  // display: none;

  .bx-pager-item {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 8px;
    border-radius: 50%;

    .bx-pager-link {
      height: 20px;
      width: 20px;
      background: #947cb3;
      overflow: hidden;
      color: transparent;
      display: inline-block;
      margin: 0 auto;
      border-radius: 50%;
    }

    .active {
      background: #344055;
    }
  }
}

// BXSLIDER CONTROLS - ARROWS

.bx-prev {
  position: absolute;
  color: transparent;
  left: 235px;
  top: 185px;
  width: 62px;
  height: 112px;
  background: url(/i/design/testimonial-arrow.png) no-repeat center;
  transform: scaleX(-1);
  background-size: contain;

  @media only screen and (max-width: 1540px) {
    left: 130px;
  }

  @media only screen and (max-width: 1290px) {
    width: 31px;
    height: 56px;
    left: 80px;
  }

  @media only screen and (max-width: 1160px) {
    left: 360px;
    top: 425px;
  }

  @media only screen and (max-width: 940px) {
    display: none;
  }

  &::before {
    position: absolute;
    content: "";
    background: rgba(0,0,0, 0.1);
    height: 364px;
    width: 364px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    transition: 0.3s;

    @media only screen and (max-width: 1540px) {
      height: 200px;
      width: 200px;
    }

    @media only screen and (max-width: 1290px) {
      display: none;
    }
  }

  &:hover {
    &::before {
      scale: 1.01;
    }
    
  }

}

.bx-next {
  position: absolute;
  color: transparent;
  right: 235px;
  top: 185px;
  width: 62px;
  height: 112px;
  background: url(/i/design/testimonial-arrow.png) no-repeat center;
  background-size: contain;

  @media only screen and (max-width: 1540px) {
    right: 130px;
  }

  @media only screen and (max-width: 1290px) {
    width: 31px;
    height: 56px;
    right: 80px;
  }

  @media only screen and (max-width: 1160px) {
    right: 360px;
    top: 425px;
  }

  @media only screen and (max-width: 940px) {
    display: none;
  }

  &::before {
    position: absolute;
    content: "";
    background: rgba(0,0,0, 0.1);
    height: 364px;
    width: 364px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    transition: 0.3s;

    @media only screen and (max-width: 1540px) {
      height: 200px;
      width: 200px;

    }

    @media only screen and (max-width: 1290px) {
      display: none;
    }
  }

  &:hover {
    &::before {
      scale: 1.01;
    }
    
  }
}


// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}
@media only screen and (max-height: 770px) { } // 770px H

@media only screen and (max-width: 1800px) { } // 1800
@media only screen and (max-width: 1500px) { } // 1500
@media only screen and (max-width: 1350px) { } // 1350

@media only screen and (max-width: 990px) { 

	// -----------------------------------//
	// HERO / SLIDER
	// -----------------------------------//

	#search input[type="search"] { width: 70%; max-width: 250px; background: #fff; border-radius: 40px; padding: 20px; color: #111; font-size: 15px; text-align: center; position: absolute; top: 50%; left: 0; right: 0; margin: -50px auto 0; outline: none; box-shadow: 0 0 0 3px #cccccc; }

	#social-dock { 
			li { }
	 }

	.strapline { }

	// Header
	header{ height: auto;
		
		.wrapper{ padding: 0; }
	}

	#logo-index  { margin: 60px auto 20px; float: none; display: block; max-width: 85%;
		a { 
			img#logo{ }
		}
	}

	// -----------------------------------//
	// HOME PAGE
	// -----------------------------------//

	// #welcome-sec { } 

	// -----------------------------------//
	// GENERAL 
	// -----------------------------------//

	body { min-width: 250px;	overflow-x: hidden; }

	h1 { font-size: 2em; }
	h2 { font-size: 1.5em; }
	h3 { font-size: 1em; }
	h4 { font-size: 1em; }

	// Wrappers
	.wrapper { width: 100%; }

	// Mainholder
	#mainholder{ width: 100%;padding: 0; }

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{ width: 100%; padding: 30px 8%; margin: 5% auto; float: none; display: block; box-sizing: border-box; border-radius: 0; }

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{ width: 100%; padding: 30px 5%; margin: 5% auto; float: none; display: block; box-sizing: border-box; }

	//Home page alert
	.modal-home{ 
		.message-modal{ width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{ 
				.modal-text{ padding: 3%; }
				a.close-modal{ position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px; }
			 }
		 }
	 }

	// Global
	img { max-width: 100%;height: auto; }
	table { max-width: 100%; }

	//Responsive tables
	table.tableborder{ width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{ content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		 }
		tbody{ width: 100%; float: left; white-space: normal; overflow-x: scroll; }
	 }

	// Calendar
	#calendar { 
      .fc-header td { display: block;width: 100%; }
      .fc-button-content { font-family: Arial; }
      .fc-header-right { text-align: left; }
      .fc-header-center { text-align: left; }
	 }

	// Forms
	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] { width: 200px; }
	form div span.button { padding-left: 0; }

	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer{ height: auto; padding: 50px 0;

		.wrapper{ width: 95%; padding: 0 5%;

			h2 { text-align: center; margin: 0 auto 30px; display: block; float: none; }

			p, a[href^="mailto:"] { text-align: center; margin: 0 auto 10px; display: block; float: none;
				&:before { display: none; }
			 }

			a { word-break: break-all; }
		 }
	 }

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer  { height: auto; line-height: normal; padding: 5px 0;

		p { text-align: center; margin: 5px auto; float: none; }

		#copyright,	#credit { float: none;
			a { margin: 0!important; display: inline-block; }
		 }
	 }

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards { display: none; }

 } // 990

// -----------------------------------//
// MOBILE
// -----------------------------------//

@media only screen and (max-width: 750px) { } // 750

@media only screen and (max-width: 600px) { 

	// slider reduced for mobile inside pages
	#slider { height: 400px!important; min-height: 400px!important; }
	.body_4521 #slider{ min-height: 600px!important; }

	// to give overlay to mobile Slider
	.backstretch { position: relative;

  	 	&:before { position: absolute;content: ''; background: rgba(0,0,0,.3); left: 0px;top:0px; width:100%; height: 100%; z-index: -1; }

    img  { top: 0px !important; }
	 }

 } // 600

@media only screen and (max-width: 650px) and (orientation:landscape) { 
	// .body_4521 #slider { min-height: 150vh!important; height: 150vh!important; }
 }

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none), all and (-ms-high-contrast: active), screen\0, screen\9 { }

// Edge fixes
@supports (-ms-ime-align:auto) { }
